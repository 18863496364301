<template>
  <div>
    <h1>Admin Page</h1>
    <button @click="showCreateProjectForm">Create Project</button>
    <button @click="showEditProjectList">Edit Project</button>
    <button @click="showAddMediaForm">Add Media</button>
    <button @click="showManageImagesPage">Images</button>

    <!-- Render the appropriate component based on the current action -->
    <CreateProjectForm v-if="currentAction === 'create'" @projectSaved="handleProjectSaved" />
    <EditProjectForm v-if="currentAction === 'edit'" :projectCode="selectedProjectCode"
      @projectSaved="handleProjectSaved" />
    <ProjectList v-if="currentAction === 'list'" @projectSelected="loadProjectForEdit" />
    <AddMediaForm v-if="currentAction === 'addMedia'" @mediaUploaded="handleMediaUploaded" />
    <ManageImagesPage v-if="currentAction === 'manageImages'" @imageDeleted="handleImageDeleted" />
  </div>
</template>

<script>
import CreateProjectForm from "./CreateProjectForm.vue";
import EditProjectForm from "./EditProjectForm.vue";
import ProjectList from "./ProjectList.vue";
import AddMediaForm from "./AddMediaForm.vue";
import ManageImagesPage from "./ManageImagesPage.vue";

export default {
  components: {
    CreateProjectForm,
    EditProjectForm,
    ProjectList,
    AddMediaForm,
    ManageImagesPage,
  },
  data() {
    return {
      currentAction: null,
      selectedProjectCode: null, // Store selected project code
    };
  },
  methods: {
    showCreateProjectForm() {
      this.currentAction = "create";
    },
    showEditProjectList() {
      this.currentAction = "list";
    },
    showAddMediaForm() {
      this.currentAction = "addMedia";
    },
    showManageImagesPage() {
      this.currentAction = "manageImages";
    },
    handleProjectSaved() {
      this.currentAction = null; // Go back to the initial view
    },
    handleMediaUploaded(url) {
      this.currentAction = null; // Go back to the initial view
      alert(`Media uploaded! URL: ${url}`);
    },
    handleImageDeleted() {
      alert("Image deleted successfully!");
    },
    loadProjectForEdit(projectCode) {
      this.selectedProjectCode = projectCode; // Set the selected project code
      this.currentAction = "edit";
    },
  },
};
</script>