<template>
  <div>
    <h2>Edit Project</h2>
    <form @submit.prevent="saveProject">
      <div>
        <label>Project Code:</label>
        <input :value="projectCode" disabled />
      </div>
      <div>
        <label>Page Template:</label>
        <textarea v-model="pageTemplate"></textarea>
      </div>
      <div>
        <label>Loading Template:</label>
        <textarea v-model="loadingTemplate"></textarea>
      </div>
      <div>
        <label>Email Confirmation Template:</label>
        <textarea v-model="emailConfirmationTemplate"></textarea>
      </div>
      <div>
        <label>Email Template:</label>
        <textarea v-model="emailTemplate"></textarea> <!-- Add email template field -->
      </div>
      <button type="submit">Save Project</button>
    </form>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: ["projectCode"],
  data() {
    return {
      pageTemplate: "",
      loadingTemplate: "",
      emailConfirmationTemplate: "",
      emailTemplate: "", // Add email template field
    };
  },
  async mounted() {
    // Fetch the project details when the component is mounted
    try {
      const response = await axios.get(`/api/projects/${this.projectCode}`);
      this.pageTemplate = response.data.pageTemplate;
      this.loadingTemplate = response.data.loadingTemplate;
      this.emailConfirmationTemplate = response.data.emailConfirmationTemplate;
      this.emailTemplate = response.data.emailTemplate; // Load email template
    } catch (error) {
      console.error("Error loading project:", error);
    }
  },
  methods: {
    async saveProject() {
      const project = {
        pageTemplate: this.pageTemplate || null,
        loadingTemplate: this.loadingTemplate || null,
        emailConfirmationTemplate: this.emailConfirmationTemplate || null,
        emailTemplate: this.emailTemplate || null, // Save email template
      };

      try {
        await axios.put(`/api/projects/edit/${this.projectCode}`, project);
        this.$emit("projectSaved");
      } catch (error) {
        console.error("Error saving project:", error);
      }
    },
  },
};
</script>