<template>
  <div>
    <h2 v-if="!mediaUploaded">Add Media</h2>
    <form v-if="!mediaUploaded" @submit.prevent="uploadMedia">
      <div>
        <label>Media Name:</label>
        <input v-model="mediaName" required />
      </div>
      <div>
        <label>Project:</label>
        <select v-model="selectedProject" required>
          <option
            v-for="project in projects"
            :key="project.projectCode"
            :value="project.projectCode"
          >
            {{ project.projectCode }}
          </option>
        </select>
      </div>
      <div>
        <label>Media File:</label>
        <input type="file" @change="handleFileUpload" required />
      </div>
      <button type="submit">Upload Media</button>
    </form>

    <!-- Display the dynamic content page URL after upload -->
    <div v-if="mediaUploaded">
      <p>Dynamic Content Page URL:</p>
      <p>{{ dynamicPageUrl }}</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      mediaName: "",
      selectedProject: "",
      file: null,
      projects: [],
      mediaUploaded: false,
      dynamicPageUrl: "", // URL to the dynamic content page
    };
  },
  async mounted() {
    // Fetch all projects to populate the dropdown
    try {
      const response = await axios.get("/api/projects");
      this.projects = response.data;
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  },
  methods: {
    handleFileUpload(event) {
      this.file = event.target.files[0]; // Get the uploaded file
    },
    async uploadMedia() {
      if (!this.file) {
        alert("Please select a file to upload.");
        return;
      }

      const formData = new FormData();
      formData.append("file", this.file);
      formData.append("mediaName", this.mediaName);
      formData.append("projectCode", this.selectedProject);

      try {
        const response = await axios.post("/api/media/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        // Store the dynamic page URL and mark media as uploaded
        this.dynamicPageUrl = response.data.url;
        this.mediaUploaded = true;
      } catch (error) {
        console.error("Error uploading media:", error);
      }
    },
    goBack() {
      this.mediaUploaded = false;
      this.mediaName = "";
      this.selectedProject = "";
      this.file = null;
      this.$emit("backToAdmin");
    },
  },
};
</script>
