<template>
  <div>
    <h2>Create Project</h2>
    <form @submit.prevent="saveProject">
      <!-- Project Code Field -->
      <div>
        <label>Project Code:</label>
        <input v-model="projectCode" required />
      </div>

      <!-- Page Template Field -->
      <div>
        <label>Page Template:</label>
        <p class="instructions">
          Please provide HTML and CSS code for your page template. Use the
          <code>&#123;&#123;media_placeholder&#125;&#125;</code> variable in the
          template where the image will be inserted. For example:
        </p>
        <pre class="code-example">
        &lt;div class="container"&gt;
          &lt;h1&gt;Your Page Title&lt;/h1&gt;
          &lt;img src="&#123;&#123;media_placeholder&#125;&#125;" alt="Media"&gt;
        &lt;/div&gt;
        </pre>
        <textarea v-model="pageTemplate" placeholder="Enter HTML + CSS here"></textarea>
      </div>

      <!-- Loading Template Field -->
      <div>
        <label>Loading Template:</label>
        <p class="instructions">
          Please provide the HTML and CSS content that will be displayed while
          the media is loading or if the media is not yet available. For
          example:
        </p>
        <pre class="code-example">
        &lt;div class="loading"&gt;
          &lt;h1&gt;Loading, please wait...&lt;/h1&gt;
        &lt;/div&gt;
        </pre>
        <textarea v-model="loadingTemplate" placeholder="Enter loading template HTML here"></textarea>
      </div>

      <!-- Email Confirmation Template Field -->
      <<div>
        <label>Email Confirmation Template:</label>
        <p class="instructions">
          Please provide the HTML and CSS content for the email confirmation page. Use the
          <code>&#123;&#123;email_input_placeholder_id&#125;&#125;</code> for the email input field and
          <code>&#123;&#123;confirm_button_placeholder_id&#125;&#125;</code> for the confirmation button.
        </p>
        <pre class="code-example">
        &lt;div class="container"&gt;
          &lt;h1&gt;Email Confirmation&lt;/h1&gt;
          &lt;input type="email" id="&#123;&#123;email_input_placeholder_id&#125;&#125;" placeholder="Enter your email" /&gt;
          &lt;button id="&#123;&#123;confirm_button_placeholder_id&#125;&#125;"&gt;Confirm&lt;/button&gt;
          &lt;script&gt;
            function handleEmailConfirmation() {
              const email = document.getElementById('&#123;&#123;email_input_placeholder_id&#125;&#125;').value;
              if (!email) {
                alert('Please enter a valid email address.');
                return;
              }
              // Replace the form with the confirmation message
              const container = document.getElementById('email-confirmation-container');
              container.innerHTML = '&lt;h1&gt;Email Confirmed&lt;/h1&gt;&lt;p&gt;Thank you for confirming your email.&lt;/p&gt;';
              return email; // Return email for use by appended script.
            }
          &lt;/script&gt;
        &lt;/div&gt;
        </pre>
        <textarea v-model="emailConfirmationTemplate"
          placeholder="Enter email confirmation template HTML here"></textarea>
  </div>

  <!-- Email Template Field -->
  <div>
    <label>Email Template:</label>
    <p class="instructions">
      Please provide the content for the email. Use the
      <code>&#123;&#123;url_placeholder&#125;&#125;</code> to customize the email content. For example:
    </p>
    <pre class="code-example">
        Hello,
        &lt;br&gt;
        Your media for project testProject is now available on the &#123;&#123;url_placeholder&#125;&#125;
        &lt;br&gt;
        Thank you!
        </pre>
    <textarea v-model="emailTemplate" placeholder="Enter email template content here"></textarea>
  </div>

  <!-- Submit Button -->
  <button type="submit">Save Project</button>
  </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      projectCode: "",
      pageTemplate: "",
      loadingTemplate: "",
      emailConfirmationTemplate: "",
      emailTemplate: "", // Added emailTemplate to the project form
    };
  },
  methods: {
    async saveProject() {
      const project = {
        projectCode: this.projectCode,
        pageTemplate: this.pageTemplate || null,
        loadingTemplate: this.loadingTemplate || null,
        emailConfirmationTemplate: this.emailConfirmationTemplate || null, // Include email confirmation template
        emailTemplate: this.emailTemplate || null, // Include email template
      };

      try {
        await this.$http.post("/api/projects/create", project); // Adjust the API endpoint
        this.$emit("projectSaved"); // Notify parent that the project has been saved
      } catch (error) {
        console.error("Error saving project:", error);
      }
    },
  },
};
</script>

<style scoped>
.instructions {
  font-style: italic;
  margin-bottom: 10px;
}

.code-example {
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 5px;
  font-family: monospace;
  margin-bottom: 10px;
}
</style>