import { createRouter, createWebHistory } from "vue-router";
import Login from "./components/Login.vue";
import Admin from "./components/Admin.vue";

const routes = [
  { path: "/", component: Login },
  { path: "/admin", component: Admin },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
