<template>
  <div>
    <h2>Select a Project to Edit</h2>
    <ul>
      <li v-for="project in projects" :key="project.projectCode">
        <button @click="$emit('projectSelected', project.projectCode)">
          {{ project.projectCode }}
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      projects: [],
    };
  },
  async mounted() {
    try {
      const response = await this.$http.get("/api/projects"); // Fetch projects
      this.projects = response.data;
    } catch (error) {
      console.error("Error loading projects:", error);
    }
  },
};
</script>
