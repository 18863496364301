<template>
    <div>
        <h2>Manage Images</h2>

        <!-- Upload Image -->
        <form @submit.prevent="uploadImage">
            <div>
                <label for="imageName">Image Name:</label>
                <input v-model="imageName" id="imageName" required />
            </div>
            <div>
                <label for="imageFile">Select Image:</label>
                <input type="file" @change="onFileChange" required />
            </div>
            <button type="submit">Upload Image</button>
        </form>

        <hr />

        <!-- Display Images -->
        <h3>Uploaded Images</h3>
        <div class="images-grid">
            <div v-for="image in images" :key="image.name" class="image-item">
                <img :src="image.url" :alt="image.name" />
                <p><strong>Name:</strong> {{ image.name }}</p>
                <p><strong>Path:</strong> {{ image.url }}</p>
                <button @click="copyToClipboard(image.url)">Copy Path</button>
                <button @click="confirmDelete(image.name)">Delete</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            imageName: "",
            imageFile: null,
            images: [], // List of images fetched from the server
        };
    },
    methods: {
        async uploadImage() {
            if (!this.imageFile) {
                alert("Please select a file to upload.");
                return;
            }

            const formData = new FormData();
            formData.append("name", this.imageName);
            formData.append("file", this.imageFile);

            try {
                const response = await this.$http.post("/api/media/images/upload", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });

                // Update the images list after upload
                this.images.push(response.data);
                alert("Image uploaded successfully!");
                this.imageName = "";
                this.imageFile = null;
            } catch (error) {
                console.error("Error uploading image:", error);
                alert("Failed to upload image.");
            }
        },
        onFileChange(event) {
            this.imageFile = event.target.files[0];
        },
        async fetchImages() {
            try {
                const response = await this.$http.get("/api/media/images");
                this.images = response.data;
            } catch (error) {
                console.error("Error fetching images:", error);
            }
        },
        async deleteImage(name) {
            try {
                await this.$http.delete(`/api/media/images/${name}`);
                this.images = this.images.filter((image) => image.name !== name);
                this.$emit("imageDeleted");
            } catch (error) {
                console.error("Error deleting image:", error);
                alert("Failed to delete image.");
            }
        },
        confirmDelete(name) {
            if (confirm(`Are you sure you want to delete the image "${name}"?`)) {
                this.deleteImage(name);
            }
        },
        copyToClipboard(text) {
            navigator.clipboard
                .writeText(text)
                .then(() => {
                    alert("Path copied to clipboard!");
                })
                .catch((err) => {
                    console.error("Failed to copy path:", err);
                });
        },
    },
    async mounted() {
        await this.fetchImages();
    },
};
</script>

<style scoped>
.images-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.image-item {
    text-align: center;
    width: 200px;
}

.image-item img {
    max-width: 100%;
    height: 150px;
    object-fit: cover;
    border: 1px solid #ddd;
    border-radius: 8px;
}

.image-item p {
    margin: 5px 0;
}

button {
    margin: 5px 0;
}
</style>